import { Form, Select } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      {...props}
      name="role"
      className="w-full"
      label="Роль"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите роль",
        },
      ]}
    >
      <Select
        placeholder="Выберите роль"
        className="w-full"
        options={[
          {
            label: "Администратор (полный доступ)",
            value: "admin",
          },
          {
            label: "Контент-менеджер",
            value: "content-manager",
          },
          {
            label: "Ресепшн",
            value: "reception",
          },
        ]}
      />
      <span className="mt-2 block text-sm w-full text-neutral-700">
        - Администраторы имеют полный доступ к панели управления.
        <br />
        - Контент-менеджеры могут редактировать контент, без доступа к данным
        комнат и обращениям пользователей.
        <br />- Ресепшн может просматривать обращения с сайта.
      </span>
    </Form.Item>
  );
}
