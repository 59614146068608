import { Divider, Card, Button, Popconfirm, Form, Select, message } from "antd";
import { SaveOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Details from "./details";
import Info from "./info";

export default function EditEntry({ data, loading, form, onDelete }) {
  const isActive = form.getFieldValue("active");
  const isReserved = form.getFieldValue("is_reserved");

  const onPreview = () => {
    if (isActive) {
      return message.error(
        "Предпросмотр доступен только в режиме «Черновика»",
        2
      );
    }

    form.setFieldsValue({ preview: true });
    form.submit();
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-9 flex flex-col gap-4">
        <Card title="Основная информация" bordered={false}>
          <div className="grid grid-cols-12 gap-x-4 gap-y-2">
            <div className="col-span-12">
              <Details data={data} form={form} />
            </div>
          </div>
        </Card>
        <Card title="Дополнительно">
          <Info form={form} />
        </Card>
      </div>
      <div className="col-span-12 md:col-span-3 flex flex-col gap-4">
        <Card title="Действия">
          <div className="flex flex-col gap-2">
            <div>
              <Form.Item
                className="w-full"
                label="Статус"
                name="active"
                rules={[
                  {
                    required: true,
                    message: "Это поле обязательно",
                  },
                ]}
              >
                <Select
                  disabled={isReserved}
                  placeholder="Выберите значение"
                  options={[
                    { label: "Черновик", value: false },
                    { label: "Опубликовано", value: true },
                  ]}
                />
              </Form.Item>
            </div>
            <Button
              loading={loading}
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
            >
              Сохранить
            </Button>
            <Button
              onClick={onPreview}
              disabled={isActive}
              icon={<EyeOutlined />}
            >
              Предпросмотр
            </Button>
            {isActive && (
              <span className="block w-full text-xs text-center text-neutral-500">
                Предпросмотр доступен только в режиме «Черновика».
              </span>
            )}
            <Divider className="my-2" />
            <Popconfirm
              okText="Да, удалить"
              title="Удалить данные?"
              description="Данные будут удалены безвозвратно. Вы уверены?"
              onConfirm={onDelete}
            >
              <Button
                loading={loading}
                type="text"
                icon={<DeleteOutlined />}
                danger
                disabled={isReserved}
              >
                Удалить
              </Button>
            </Popconfirm>
          </div>
        </Card>
        <Card title="Настройки навигации">
          <span className="block w-full text-center text-sm text-black">
            Чтобы изменить отображение ссылки на страницу в верхней навигации,
            опубликуйте страницу и перейдите в{" "}
            <Link to="/settings/nav" className="text-blue-500 hover:underline">
              Настройки
            </Link>
          </span>
        </Card>
      </div>
    </div>
  );
}
