import Widget from "./widget";
import WidgetScript from "./widget_script";

export default function Bookings({ data, form }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Widget form={form} />
      </div>
      <div className="col-span-12">
        <WidgetScript form={form} />
      </div>
    </div>
  );
}
