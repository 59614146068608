import { Modal, Input, Divider, Empty, Button, Popconfirm } from "antd";
import {
  PlusOutlined,
  UpOutlined,
  DownOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import * as Pool from "../edit/pool";

export default function EditCanal({
  data,
  open,
  onCancel,
  onChange,
  defaults,
  onDefaultsChange,
}) {
  const [canal, setCanal] = useState(data);
  const [pool, setPool] = useState(null);
  const [newPoolModal, setNewPoolModal] = useState(false);

  useEffect(() => {
    if (!open) return;
    setCanal(data);
  }, [open]);

  const onCanalLabelsChange = (e) => {
    setCanal({ ...canal, [e.target.name]: e.target.value });
  };

  const onPoolAdd = (newPool) => {
    setCanal({
      ...canal,
      pools: [...(canal?.pools || []), newPool],
    });
    newPoolModalTrigger();
    setPool(newPool);
  };

  const newPoolModalTrigger = () => {
    setNewPoolModal(!newPoolModal);
  };

  const onPoolPositionChange = (oldIndex, newIndex) => {
    const newData = [...canal?.pools];
    const item = newData.splice(oldIndex, 1)[0];
    newData.splice(newIndex, 0, item);
    setCanal({
      ...canal,
      pools: newData,
    });
  };

  const onPoolChange = (editedPool) => {
    setCanal({
      ...canal,
      pools: canal?.pools?.map((pool) =>
        pool?.id === editedPool?.id ? editedPool : pool
      ),
    });
    setPool(null);
  };

  const onPoolDelete = (idx) => {
    setCanal({
      ...canal,
      pools: canal?.pools?.filter((pool, _idx) => _idx !== idx),
    });
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      cancelText="Отменить"
      okText="Сохранить"
      centered
      width={750}
      title="Изменение данных протока"
      okButtonProps={{
        disabled: !canal?.label || !canal?.label_kk || !canal?.label_en,
      }}
      onOk={() => onChange(canal)}
    >
      <div className="grid grid-cols-12 gap-3 my-5">
        <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-русском)
          </span>
          <Input
            className="w-full"
            value={canal?.label}
            name="label"
            onChange={onCanalLabelsChange}
            placeholder="Введите название протока"
          />
        </div>
        <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-казахском)
          </span>
          <Input
            className="w-full"
            value={canal?.label_kk}
            name="label_kk"
            onChange={onCanalLabelsChange}
            placeholder="Введите название протока"
          />
        </div>
        <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-английском)
          </span>
          <Input
            className="w-full"
            value={canal?.label_en}
            name="label_en"
            onChange={onCanalLabelsChange}
            placeholder="Введите название протока"
          />
        </div>
        <div className="col-span-12">
          <Divider className="my-2" orientation="left">
            Пулы протока
          </Divider>
        </div>
        <div className="col-span-12 bg-neutral-50 p-3 rounded-lg flex flex-col gap-2">
          {(!canal?.pools || canal?.pools?.length === 0) && (
            <Empty
              description="В этом протоке пока нет данных. Добавьте пул, нажав на кнопку ниже."
              className="my-3"
            />
          )}
          {canal?.pools?.map((poolItem, index) => (
            <div className="w-full flex flex-wrap justify-between items-center">
              <div className="block w-full max-w-xs text-sm font-medium">
                {poolItem?.label}
                <i className="not-italic block text-sm font-normal text-neutral-600">
                  Глубина: {poolItem?.depth}{" "}
                  {poolItem?.depth_max && `- ${poolItem?.depth_max}`} м.
                </i>
              </div>
              <Button.Group>
                <Button
                  type="text"
                  disabled={index === 0}
                  onClick={() => onPoolPositionChange(index, index - 1)}
                  icon={<UpOutlined />}
                />
                <Button
                  type="text"
                  disabled={index === data?.canals?.length - 1}
                  onClick={() => onPoolPositionChange(index, index + 1)}
                  icon={<DownOutlined />}
                />
                <Button
                  type="text"
                  onClick={() => setPool(poolItem)}
                  icon={<EditOutlined />}
                />
                <Popconfirm
                  okText="Да, удалить"
                  title="Удалить данные?"
                  description="Данные будут удалены безвозвратно. Вы уверены?"
                  onConfirm={() => onPoolDelete(index)}
                >
                  <Button type="text" danger icon={<CloseOutlined />} />
                </Popconfirm>
              </Button.Group>
            </div>
          ))}
          <Button
            className="mt-2"
            block
            icon={<PlusOutlined />}
            onClick={newPoolModalTrigger}
          >
            Добавить пул
          </Button>
        </div>
      </div>
      <Pool.New
        open={newPoolModal}
        onClose={newPoolModalTrigger}
        onAdd={onPoolAdd}
      />
      <Pool.Edit
        open={!!pool}
        data={pool}
        onClose={() => setPool(null)}
        onChange={onPoolChange}
        defaults={defaults}
        onDefaultsChange={onDefaultsChange}
      />
    </Modal>
  );
}
