import { Components } from "@modules";
import { Tabs, Divider, Tag, Button } from "antd";
import Name from "./name";
import Headline from "./headline";
import { useState, useEffect } from "react";
import { CopyOutlined } from "@ant-design/icons";

export default function Details({ form }) {
  const [locale, setLocale] = useState("ru");
  const [components, setComponents] = useState(
    form.getFieldValue("components") || {}
  );

  useEffect(() => {
    form.setFieldsValue({
      components,
    });
  }, [components]);

  const locales = [
    {
      name: "Русский",
      suffix: "",
      code: "ru",
    },
    {
      name: "Қазақша",
      suffix: "_kk",
      code: "kk",
    },
    {
      name: "English",
      suffix: "_en",
      code: "en",
    },
  ];

  const onComponentsChange = (_components) => {
    setComponents({
      ...components,
      [locale]: _components,
    });
  };

  const onComponentsDuplicate = () => {
    const ru = [...JSON.parse(JSON.stringify(components))?.ru] || [];
    setComponents({
      ...JSON.parse(JSON.stringify(components)),
      [locale]: ru,
    });
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Tabs
          defaultActiveKey={locale}
          onChange={(key) => setLocale(key)}
          items={locales.map((locale) => ({
            key: locale.code,
            label: locale.name,
            forceRender: true,
            children: (
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <Name
                    name={`name${locale.suffix}`}
                    help={`Язык: ${locale.name}`}
                  />
                </div>
                <div className="col-span-12">
                  <Headline
                    name={`headline${locale.suffix}`}
                    help={`Язык: ${locale.name}`}
                  />
                </div>
              </div>
            ),
          }))}
        />
      </div>
      <div className="col-span-12">
        <Divider className="m-0" />
      </div>
      <div className="col-span-12">
        <span className="block font-semibold text-lg mb-2">
          Контент страницы{" "}
          <Tag>{locales?.find((l) => l.code === locale)?.name}</Tag>
        </span>
        <Components.Editor
          locale={locale}
          key={locale}
          data={components[locale]}
          onChange={onComponentsChange}
          className="m-0"
        />
        <Button
          hidden={locale === "ru" || components[locale]?.length > 0}
          onClick={onComponentsDuplicate}
          icon={<CopyOutlined />}
          block
        >
          Дублировать компоненты с русской версии
        </Button>
      </div>
    </div>
  );
}
