import { Modal, Input, Divider, Button, Popconfirm } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

export default function FishingStyles({
  open,
  onCancel,
  defaults,
  onDefaultsChange,
}) {
  const onNewFishingStyleAdd = () => {
    onDefaultsChange({
      ...defaults,
      fishing_styles: [
        ...(defaults?.fishing_styles || []),
        {
          label: "Новый вид",
          label_kk: "Жаңа түр",
          label_en: "New type",
          id: Date.now(),
        },
      ],
    });
  };

  const onFishingStyleDelete = (idx) => {
    const newData = [...defaults?.fishing_styles];
    newData.splice(idx, 1);
    onDefaultsChange({
      ...defaults,
      fishing_styles: newData,
    });
  };

  const onFishingStyleChange = (e, idx) => {
    const newData = [...defaults?.fishing_styles];
    newData[idx] = { ...newData[idx], [e.target.name]: e.target.value };
    onDefaultsChange({
      ...defaults,
      fishing_styles: newData,
    });
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      centered
      width={750}
      okText="Готово"
      cancelButtonProps={{ hidden: true }}
      onOk={onCancel}
      title="Виды рыбалки"
    >
      <div className="w-full my-2 bg-neutral-50 p-3 rounded-lg">
        {defaults?.fishing_styles?.map((fish, idx) => (
          <div key={idx} className="grid grid-cols-12 gap-2">
            <div className="col-span-12 flex justify-between itesm-center">
              <span className="text-base block font-medium">{fish?.label}</span>
              <Popconfirm
                title="Вы уверены?"
                description="Связанные с этим видом рыбалки данные могут потеряться."
                onConfirm={() => onFishingStyleDelete(idx)}
                okText="Удалить"
              >
                <Button
                  className="w-fit h-fit min-h-0 m-0 p-0"
                  size="small"
                  type="link"
                  danger
                  icon={<DeleteOutlined />}
                >
                  Удалить
                </Button>{" "}
              </Popconfirm>
            </div>
            <div className="col-span-12 md:col-span-4">
              <span className="text-sm block w-full mb-2">Название (рус.)</span>
              <Input
                placeholder="Введите название"
                value={fish?.label}
                className="w-full"
                name="label"
                onChange={(e) => onFishingStyleChange(e, idx)}
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <span className="text-sm block w-full mb-2">Название (каз.)</span>
              <Input
                placeholder="Введите название"
                value={fish?.label_kk}
                className="w-full"
                name="label_kk"
                onChange={(e) => onFishingStyleChange(e, idx)}
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <span className="text-sm block w-full mb-2">Название (анг.)</span>
              <Input
                placeholder="Введите название"
                value={fish?.label_en}
                className="w-full"
                name="label_en"
                onChange={(e) => onFishingStyleChange(e, idx)}
              />
            </div>
            <div className="col-span-12 flex justify-between itesm-center">
              <Divider className="my-2" />
            </div>
          </div>
        ))}
        <Button
          onClick={onNewFishingStyleAdd}
          icon={<PlusOutlined />}
          block
          className="mt-2"
        >
          Добавить вид
        </Button>
      </div>
    </Modal>
  );
}
