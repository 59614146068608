import { Modal, Input } from "antd";
import { useEffect, useState } from "react";
import Image from "./image";

export default function PageSelector({ open, onCancel, onAdd }) {
  const [data, setData] = useState({});

  useEffect(() => {
    if (!open) return;
    setData({});
  }, [open]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Добавить карточку сотрудника"
      centered
      width={720}
      okText="Добавить"
      okButtonProps={{
        disabled:
          !data?.name ||
          !data?.position ||
          !data?.position_kk ||
          !data?.position_en ||
          !data?.image,
      }}
      onOk={() => {
        onAdd(data);
      }}
    >
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Image
            data={data?.image}
            onChange={(image) => setData({ ...data, image })}
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Имя
          </span>
          <Input
            value={data?.name}
            onChange={(e) => {
              setData({
                ...data,
                name: e.target.value,
              });
            }}
            placeholder="Введите имя сотрудника"
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Должность (на-русском)
          </span>
          <Input
            value={data?.position}
            onChange={(e) => {
              setData({
                ...data,
                position: e.target.value,
              });
            }}
            placeholder="Введите должность"
          />
        </div>
        <div key="position_kk" className="col-span-12 md:col-span-4">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Должность (на-казахском)
          </span>
          <Input
            value={data?.position_kk}
            onChange={(e) => {
              setData({
                ...data,
                position_kk: e.target.value,
              });
            }}
            placeholder="Введите должность"
          />
        </div>
        <div key="position_en" className="col-span-12 md:col-span-4">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Должность (на-английском)
          </span>
          <Input
            value={data?.position_en}
            onChange={(e) => {
              setData({
                ...data,
                position_en: e.target.value,
              });
            }}
            placeholder="Введите должность"
          />
        </div>
      </div>
    </Modal>
  );
}
