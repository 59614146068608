import { Divider, Form, Input } from "antd";

export default function InputField(props) {
  const isReserved = props?.form?.getFieldValue("is_reserved");

  return (
    <div className="flex flex-col gap-4">
      <Form.Item
        name="slug"
        className="w-full"
        label="URL"
        help="URL заполняется автоматически из названия. Вы можете изменить его вручную, но не рекомендуется из-за риска дублирования."
        rules={[
          {
            required: true,
            message: "Пожалуйста, заполните URL",
          },
        ]}
      >
        <Input
          disabled={isReserved}
          controls={false}
          className="w-full"
          placeholder="URL заполняется автоматически"
        />
      </Form.Item>
      <Form.Item
        name={["meta", "seo_title"]}
        className="w-full"
        label="SEO Заголовок"
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="Заголовок для поисковых систем"
        />
      </Form.Item>
      <Form.Item
        name={["meta", "seo_description"]}
        className="w-full"
        label="SEO Описание"
      >
        <Input.TextArea
          rows={3}
          controls={false}
          className="w-full"
          placeholder="Описание для поисковых систем"
        />
      </Form.Item>
      <Divider className="my-2" />
      <Form.Item
        name={["meta", "seo_title_kk"]}
        className="w-full"
        label="SEO Заголовок (для казахского языка)"
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="Заголовок для поисковых систем (для казахского языка)"
        />
      </Form.Item>
      <Form.Item
        name={["meta", "seo_description_kk"]}
        className="w-full"
        label="SEO Описание (для казахского языка)"
      >
        <Input.TextArea
          rows={3}
          controls={false}
          className="w-full"
          placeholder="Описание для поисковых систем (для казахского языка)"
        />
      </Form.Item>
      <Divider className="my-2" />
      <Form.Item
        name={["meta", "seo_title_en"]}
        className="w-full"
        label="SEO Заголовок (для английского языка)"
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="Заголовок для поисковых систем (для английского языка)"
        />
      </Form.Item>
      <Form.Item
        name={["meta", "seo_description_en"]}
        className="w-full"
        label="SEO Описание (для английского языка)"
      >
        <Input.TextArea
          rows={3}
          controls={false}
          className="w-full"
          placeholder="Описание для поисковых систем (для английского языка)"
        />
      </Form.Item>
    </div>
  );
}
