import { Alert, Drawer, Input, Button, InputNumber } from "antd";
import { useEffect, useState } from "react";

export default function NewPool({ open, onClose, onAdd }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!open) return setData(null);
  }, [open]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={500}
      title="Добавление нового пула"
    >
      <div className="grid grid-cols-12 gap-3">
        <Alert
          className="col-span-12"
          description="Заполните данные для нового пула. После создания пула, вы можете добавить более подробную информацию."
        />
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-русском)
          </span>
          <Input
            className="w-full"
            value={data?.label}
            name="label"
            onChange={onChange}
            placeholder="Введите название пула"
          />
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-казахском)
          </span>
          <Input
            className="w-full"
            value={data?.label_kk}
            name="label_kk"
            onChange={onChange}
            placeholder="Введите название пула"
          />
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-английском)
          </span>
          <Input
            className="w-full"
            value={data?.label_en}
            name="label_en"
            onChange={onChange}
            placeholder="Введите название пула"
          />
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Глубина (м.)
          </span>
          <InputNumber
            min={0}
            className="w-full"
            value={data?.depth}
            name="depth"
            onChange={(depth) => setData({ ...data, depth })}
            placeholder="Введите глубину"
          />
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Глубина макс. (м.)
          </span>
          <InputNumber
            min={data?.depth || 0}
            className="w-full"
            value={data?.depth_max}
            name="depth_max"
            onChange={(depth_max) => setData({ ...data, depth_max })}
            placeholder="Введите макс. глубину"
          />
        </div>
        <div className="col-span-12 py-3">
          <Button
            onClick={() =>
              onAdd({
                ...data,
                id: Date.now(),
              })
            }
            disabled={
              !data?.label || !data?.label_kk || !data?.label_en || !data?.depth
            }
            block
            type="primary"
          >
            Добавить пул
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
