import { Loading } from "@components";
import { Descriptions, message, Tag, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "@contexts";
import classNames from "classnames";
import dayjs from "dayjs";
import * as API from "@api";

const { Item } = Descriptions;

export default function User({ className, ...props }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser?.role !== "admin") {
      if (currentUser?.id !== props?.id) {
        return navigate("/404");
      }
    }
  }, [currentUser]);

  useEffect(() => {
    API.Users.getOne({ id: props?.id })
      .then(async (res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch(() => {
        message.error("Ошибка загрузки данных, попробуйте позже", 2);
        setLoading(false);
      });
  }, []);

  if (loading || !data) {
    return <Loading compact />;
  }

  return (
    <div key={props?.id} className={classNames("w-full", className)}>
      <Descriptions
        extra={
          <Link to={`/users/edit/${data?.id}`}>
            <Button icon={<EditOutlined />}>Edit User</Button>
          </Link>
        }
        size="small"
        layout="vertical"
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        title="Информация о пользователе"
        bordered
        colon={false}
      >
        <Item label="Имя">{data?.display_name}</Item>
        <Item label="Эл.почта">{data?.email}</Item>
        <Item label="Роль">
          {data?.role === "admin" && <Tag color="blue">Администратор</Tag>}
          {data?.role === "content-manager" && (
            <Tag color="blue">Контент-менеджер</Tag>
          )}
          {data?.role === "reception" && <Tag color="blue">Ресепшн</Tag>}
        </Item>
        <Item label="Статус">
          <Tag color={data?.blocked ? "red" : "green"}>
            {data?.blocked ? "Заблокирован" : "Активный"}
          </Tag>
        </Item>
        <Item label="Посл. вход">
          {dayjs(data?.updated_at).format("HH:mm · DD MMM YYYY")}
        </Item>
        <Item label="IP посл. входа">{data?.last_login_ip}</Item>
      </Descriptions>
    </div>
  );
}
