import { Form, Input } from "antd";

export default function InputField(props) {
  return (
    <div className="flex flex-col gap-4">
      <Form.Item
        name="slug"
        className="w-full"
        label="URL"
        help="URL заполняется автоматически из названия. Вы можете изменить его вручную, но не рекомендуется из-за риска дублирования."
        rules={[
          {
            required: true,
            message: "Пожалуйста, заполните URL",
          },
        ]}
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="URL заполняется автоматически"
        />
      </Form.Item>
      <Form.Item
        name={["meta", "seo_title"]}
        className="w-full"
        label="SEO Заголовок"
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="Заголовок для поисковых систем"
        />
      </Form.Item>
      <Form.Item
        name={["meta", "seo_description"]}
        className="w-full"
        label="SEO Описание"
      >
        <Input.TextArea
          rows={3}
          controls={false}
          className="w-full"
          placeholder="Описание для поисковых систем"
        />
      </Form.Item>
    </div>
  );
}
