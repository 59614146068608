import { useState, useEffect } from "react";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import * as API from "@api";

export default function EditData() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Settings.getOne({ type: "global" }).then((res) => {
      if (res.error || !res) {
        return message.error(
          "Невозможно запросить данные. Пожалуйста, попробуйте снова позже.",
          2
        );
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);

    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );

    API.Settings.update({
      id: data?.id,
      type: "global",
      data: normalizedValues,
    })
      .then(async (res) => {
        setLoading(false);
        if (res?.error || !res?.data) {
          throw new Error(res?.message);
        }

        // Revalidate
        try {
          setLoading(true);
          await API.Revalidate.revalidateAll().then(() => setLoading(false));
        } catch (err) {
          setLoading(false);
          console.error(err);
          message.error("Не удалось обновить данные на сайте", 2);
        }

        return message.success("Данные успешно обновлены", 1);
      })
      .catch((err) => {
        setLoading(false);
        return message.error(
          err.message || "Данные не удалось обновить, проверье форму отправки",
          2
        );
      });
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data?.data || null}
    >
      <DataForm form={form} data={data?.data} loading={loading} />
    </Form>
  );
}
