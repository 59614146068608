import { Divider } from "antd";
import Price from "./price";
import Currency from "./currency";
import Type from "./type";
import Slider from "./slider";
import SEO from "./seo";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 sm:col-span-6">
        <Type {...props} />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Slider {...props} />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Currency {...props} />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Price {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2">SEO</Divider>
      </div>
      <div className="col-span-12">
        <SEO {...props} />
      </div>
    </div>
  );
}
