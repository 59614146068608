import { Form, Select } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      {...props}
      name="cta_action"
      className="w-full"
      label="Функция кнопки «Забронировать» в верхнем меню"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите опцию",
        },
      ]}
    >
      <Select
        placeholder="Выберите опцию"
        className="w-full"
        options={[
          {
            label: "Открыть форму «Бронирование»",
            value: "booking_modal",
          },
          {
            label: "Открыть страницу «Контакты»",
            value: "contacts_page",
          },
          {
            label: "Набрать основной номер базы отдыха",
            value: "tel_main",
          },
          {
            label: "Открыть форму почтового клиента с адресом базы отдыха",
            value: "email_main",
          },
          {
            label: "Не показывать кнопку",
            value: "hidden",
          },
        ]}
      />
    </Form.Item>
  );
}
