import { Form, InputNumber } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      {...props}
      name="price"
      className="w-full"
      label="Стоимость"
      help="Введите 0, если не хотите указывать цену."
      rules={[
        {
          required: true,
          message:
            "Стоимость обязательна. Введите 0, если не хотите указывать цену.",
        },
      ]}
    >
      <InputNumber
        min={0}
        controls={false}
        className="w-full"
        decimalSeparator="."
        placeholder="Введите стоимость, напр. 550.00"
      />
    </Form.Item>
  );
}
